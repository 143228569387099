export default defineComponent({
  name: 'Card',
  props: {
    title: String,
    image: {
      type: String,
      default: null
    },
    flex: {
      type: Boolean,
      default: false
    },
    dropPadding: {
      type: Boolean,
      default: false
    }
  }
});