<template>
  <in-page-nav-bottom v-if="$vuetify.breakpoint.smAndDown" :navigation="navArray" />
</template>

<script lang="ts">
import Vue from 'vue'
import { mapGetters } from 'vuex'

import InPageNavBottom from '~/components/in-page-nav/InPageNavBottom.vue'

interface Computed {
  companyIsEnterprise: boolean
  navArray: (NavigationItem | NavigationDivider)[]
}

export default Vue.extend<void, void, Computed, void>({
  name: 'BusinessProfileNav',
  components: {
    InPageNavBottom,
  },
  computed: {
    // TODO: remove when autodeletion goes global
    ...mapGetters('business', ['companyIsEnterprise']),
    navArray() {
      const nav: (NavigationItem | NavigationDivider)[] = [
        {
          routeName: 'business-profile',
          displayTitle: this.$t('business.navigation.business_profile'),
          icon: '$vuetify.icons.business_profile',
          cypressIdentifier: 'profileButton',
        },
        {
          routeName: 'business-profile-aes-for-business',
          displayTitle: this.$t('business.profile.navigation.aes'),
          icon: '$vuetify.icons.business_aes',
          cypressIdentifier: 'AESForBusinessButton',
        },
        {
          routeName: 'logout',
          displayTitle: this.$t('global.navigation.logout'),
          icon: '$vuetify.icons.leave',
          cypressIdentifier: 'logoutButton',
        },
      ]
      return nav
    },
  },
})
</script>

<style lang="sass"></style>
